import request from '@/utils/request'

// 查询票根兑换码列表
export function listTicketStubCode(query) {
  return request({
    url: '/ticket/stub/code/list',
    method: 'get',
    params: query
  })
}

// 查询票根兑换码详细
export function getTicketStubCode(id) {
  return request({
    url: '/ticket/stub/code/' + id,
    method: 'get'
  })
}

// 新增票根兑换码
export function addTicketStubCode(data) {
  return request({
    url: '/ticket/stub/code',
    method: 'post',
    data: data
  })
}

// 修改票根兑换码
export function updateTicketStubCode(data) {
  return request({
    url: '/ticket/stub/code',
    method: 'put',
    data: data
  })
}

// 删除票根兑换码
export function delTicketStubCode(id) {
  return request({
    url: '/ticket/stub/code/' + id,
    method: 'delete'
  })
}

// 批量生成票码
export function batchGenerateTicketCode(data) {
  return request({
    url: '/ticket/stub/code/generate/batch',
    method: 'post',
    data: data
  })
}

// 批量修改状态
export function batchChangeStatus(data) {
  return request({
    url: '/ticket/stub/code/change/status',
    method: 'put',
    data: data
  })
}
