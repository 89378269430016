<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="票根名" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入票根名" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="可交易次数 默认3次" prop="transNumber">
                  <a-input v-model="queryParam.transNumber" placeholder="请输入可交易次数 默认3次" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['ticket:stub:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['ticket:stub:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['ticket:stub:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['ticket:stub:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        :data-source="list"
        :rowKey="(record, index) => index + 1"
        :columns="columns"
        :customRow="columnClick"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="type" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TicketStubTypeEnum" :value="record.type"/>
        </span>
        <span slot="level" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TicketStubLevelEnum" :value="record.level"/>
        </span>
        <span slot="materialType" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TicketStubMaterialTypeEnum" :value="record.materialType"/>
        </span>
        <div slot="stubFrontImg" slot-scope="text, record" style="cursor: pointer;">
          <img v-if="record.stubFrontImg" :src="record.stubFrontImg" style="width:60px;" preview  alt=""/>
        </div>
        <div slot="stubBackImg" slot-scope="text, record" style="cursor: pointer;">
          <img v-if="record.stubBackImg" :src="record.stubBackImg" style="width:60px;" preview  alt=""/>
        </div>
        <span slot="status" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TicketStubStatusEnum" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleBatchAdd(record)" v-hasPermi="['ticket:stub/code:batch:add']">
            <a-icon type="plus-square" />批量生成兑换码
          </a>
          <a-divider type="vertical" v-hasPermi="['ticket:stub:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['ticket:stub:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['ticket:stub:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['ticket:stub:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <a-modal
      ref="batchAddRef"
      title="批量生成兑换码"
      :width="500"
      :visible="batchAddModal"
      @ok="batchGenerate"
      @cancel="closeModal"
    >
      <a-form-model-item label="生成数量">
        <a-input-number
          v-model="generateNumber"
          style="width: 100%"
          :min="0"
          :max="9999"
          :precision="0"
          placeholder="请输入生成数量"
        />
      </a-form-model-item>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { listTicketStub, delTicketStub } from '@/api/ticket/ticketStub'
import { batchGenerateTicketCode } from '@/api/ticket/ticketStubCode'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from '@/components/DictCustomTag/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TicketStub',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        type: null,
        scriptId: null,
        level: null,
        materialType: null,
        transNumber: null,
        stubFrontImg: null,
        stubBackImg: null,
        isDiyImg: null,
        isDiyContent: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '票根名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '票根类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本名称',
          dataIndex: 'scriptName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '剧本id 剧本票根时使用',
        //   dataIndex: 'scriptId',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '票根等级',
          dataIndex: 'level',
          scopedSlots: { customRender: 'level' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '制作材料类型',
        //   dataIndex: 'materialType',
        //   scopedSlots: { customRender: 'materialType' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '可交易次数',
          dataIndex: 'transNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '票根正面图片',
          dataIndex: 'stubFrontImg',
          scopedSlots: { customRender: 'stubFrontImg' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '票根背面图片',
          dataIndex: 'stubBackImg',
          scopedSlots: { customRender: 'stubBackImg' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否可以自定义图片 0.否 1.是',
        //   dataIndex: 'isDiyImg',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否可以自定义内容 0.否 1.是',
        //   dataIndex: 'isDiyContent',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: 170,
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],

      batchAddModal: false,
      // 批量生成的id
      generateTicketId: null,
      generateNumber: 0
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询票根列表 */
    getList () {
      this.loading = true
      listTicketStub(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: null,
        scriptId: null,
        level: null,
        materialType: null,
        transNumber: null,
        stubFrontImg: null,
        stubBackImg: null,
        isDiyImg: null,
        isDiyContent: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    verifyStatus() {
      this.single = this.selectedRowKeys.length !== 1
      this.multiple = !this.selectedRowKeys.length
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.verifyStatus()
    },
    columnClick(row, index) {
      return {
        on: {
          click: () => {
            if (this.selectedRowKeys.includes(index + 1)) {
              const keyIndex = this.selectedRowKeys.findIndex(i => i === index + 1)
              this.selectedRowKeys.splice(keyIndex, 1)
              this.selectedRows.splice(keyIndex, 1)
              this.ids.splice(keyIndex, 1)
              this.verifyStatus()
            } else {
              this.selectedRowKeys.push(index + 1)
              this.selectedRows.push(row)
              this.ids.push(row.id)
              this.verifyStatus()
            }
          }
        }
      }
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delTicketStub(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      const that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('ticket/ticketStub/export', {
            ...that.queryParam
          }, `ticketStub_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    // 批量生成兑换码
    handleBatchAdd(record) {
      if (record.status === 0) {
        this.$message.warning('请先启用票根再进行批量生成！')
      } else {
        this.generateTicketId = record.id
        this.batchAddModal = true
      }
    },
    batchGenerate () {
      if (this.generateNumber == null || this.generateNumber === 0) {
        this.$message.warning('请输入正确数量进行生成！')
      } else {
        const data = {
          ticketId: this.generateTicketId,
          count: this.generateNumber
        }
        batchGenerateTicketCode(data).then(res => {
          this.$message.success(
            '批量生成兑换码成功，请移步“票根兑换码列表”查看！',
            3
          )
          this.closeModal()
        })
      }
    },
    // 关闭模态框
    closeModal () {
      this.batchAddModal = false
      this.generateNumber = 0
    }
  }
}
</script>
